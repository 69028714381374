<template>
  <div>
    <template>
      <el-radio-group v-model="bodyTypeRadio" @input="bodyTypeHandle">
        <el-radio :label="'none'">none</el-radio>
        <el-radio :label="'form-data'">form-data</el-radio>
        <el-radio :label="'x-www-form-urlencoded'">x-www-form-urlencoded</el-radio>
        <el-radio :label="'json'">json</el-radio>
      </el-radio-group>
    </template>
    <div :style="{ display: bodyDiv, marginTop: '15px'}">
      <div v-if="bodyTypeRadio==='form-data'">
        <el-input v-model="bodyData.bodyContent.key"></el-input>
        <el-input v-model="bodyData.bodyContent.value"></el-input>
        <el-input v-model="bodyData.bodyContent.desc"></el-input>
      </div>
      <div v-if="bodyTypeRadio==='x-www-form-urlencoded'">
        <el-input v-model="bodyData.bodyContent.key"></el-input>
        <el-input v-model="bodyData.bodyContent.value"></el-input>
      </div>
      <div v-if="bodyTypeRadio==='json'">
        <!-- <el-input v-model="bodyContentJsonStr" type="textarea" @blur="verifyJson"></el-input> -->
        <vue-monaco v-model="bodyContentJsonStr"
          @change="checkJson"
          style="height: 300px;"
          language="python"
          theme="vs"
          automaticLayout: true
          codeLens: false>
        </vue-monaco>
        <div>检测状态
          <i :class="isJson ? 'el-icon-success': 'el-icon-error'" :style="{color: isJson ? '#67C23A':'#F56C6C'}"></i>
        </div>
        <div>
          上一次检测成功的数据：{{ bodyData.bodyContent }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApiCaseBodyTag',
  props: ['isAdd', 'caseBodyInfoTag'],
  data () {
    return {
      bodyData: {
        bodyType: 'none',
        bodyContent: ''
      },
      bodyContentJsonStr: '{}',
      bodyTypeRadio: 'none', // 请求类型
      bodyDiv: 'none',
      isJson: true
    }
  },
  created () {
    console.log('获取的父组件caseBodyInfoTag值：', this.caseBodyInfoTag)
    this.bodyInitData()
  },
  watch: {
    caseBodyInfoTag (newData, oldData) {
      this.bodyData = newData
      this.bodyTypeRadio = this.bodyData.bodyType
      if (this.bodyTypeRadio === 'none') {
        this.bodyDiv = 'none'
      }
      if (this.bodyTypeRadio === 'json') {
        this.bodyDiv = 'block'
        this.bodyContentJsonStr = JSON.stringify(newData.bodyContent)
      }
    }
  },
  methods: {
    verifyJson () {
      if (this.bodyContentJsonStr) {
        try {
          this.bodyData.bodyContent = JSON.parse(this.bodyContentJsonStr);
          this.isJson = true
        } catch (error) {
          this.isJson = false
          return this.$message.warning('请输入正确json格式内容')
        }
      }
    },
    checkJson () {
      // 如果3s内没有编辑操作，则检测json格式
      // 清除之前的定时器
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      // 设置新的定时器，在3秒后执行特定的代码
      this.timeoutId = setTimeout(() => {
        this.verifyJson()
      }, 1000);
    },
    bodyTypeHandle () {
      console.log('单选值变化执行')
      if (this.bodyTypeRadio === 'none') {
        this.bodyDiv = this.bodyTypeRadio
        this.bodyData.bodyType = this.bodyTypeRadio
        this.bodyData.bodyContent = ''
      }
      if (this.bodyTypeRadio === 'form-data') {
        this.bodyDiv = 'block'
        this.bodyData.bodyType = this.bodyTypeRadio
        this.bodyData.bodyContent = [{
          key: '',
          value: '',
          desc: ''
        }]
      }
      if (this.bodyTypeRadio === 'x-www-form-urlencoded') {
        this.bodyDiv = 'block'
        this.bodyData.bodyType = this.bodyTypeRadio
        this.bodyData.bodyContent = [{
          key: '',
          value: '',
          desc: ''
        }]
      }
      if (this.bodyTypeRadio === 'json') {
        this.bodyDiv = 'block'
        this.bodyData.bodyType = this.bodyTypeRadio
        this.bodyData.bodyContent = '{}'
      }
    },
    bodyInitData () {
      console.log('编辑获取的body值==>', this.caseBodyInfoTag)
      console.log('编辑获取的body值2==>', this.caseBodyInfoTag.bodyContent)
      this.bodyData = this.caseBodyInfoTag
      this.bodyTypeRadio = this.bodyData.bodyType
      if (this.bodyTypeRadio === 'none') {
        this.bodyDiv = 'none'
      }
      if (this.bodyTypeRadio === 'json') {
        this.bodyDiv = 'block'
        this.bodyContentJsonStr = JSON.stringify(this.caseBodyInfoTag.bodyContent)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
