<template>
  <div class="apiBaseInfoTag">
    <el-form :model="apiBaseInfoRuleForm" :rules="apiBaseInfoRules" ref="apiBaseInfoRuleFormRef" label-width="120px" class="demo-ruleForm">
      <el-form-item v-if="!isAdd" label="用例ID" prop="id">
        <el-input v-model="apiBaseInfoRuleForm.id" disabled></el-input>
      </el-form-item>
      <el-form-item label="用例名称" prop="case_name">
        <el-input v-model="apiBaseInfoRuleForm.case_name"></el-input>
      </el-form-item>
      <!-- <el-form-item label="所属接口ID" prop="api_base_id">
        <el-input v-model="apiBaseInfoRuleForm.api_base_id" :disabled=" isAdd ? false : true "></el-input>
      </el-form-item> -->
      <el-form-item label="项目/模块/接口">
        <el-cascader
          :disabled=" isAdd ? false : true "
          v-model="caseCascaderValue"
          :options="caseCascaderOptions"
          :props="{ expandTrigger: 'hover', label: 'name', value: 'id'}"
          @change="caseHandleChange"
          style="width: 100%;">
        </el-cascader>
      </el-form-item>
      <el-form-item label="是否开启">
        <el-switch v-model="apiBaseInfoRuleForm.status" active-value="1" inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item label="用例描述" prop="description">
        <el-input type="textarea" v-model="apiBaseInfoRuleForm.description"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { queryApiBaseInfoByID } from '@/services/apiBaseInfo.js'
import { queryCascader } from '@/services/apiCascader.js'
export default {
  name: 'ApiCaseBaseInfoTag',
  props: ['isAdd', 'caseBaseInfoTag'],
  data () {
    return {
      apiBaseInfoRuleForm: {
        id: '',
        case_name: '',
        api_base_id: '',
        status: '1',
        description: ''
      },
      caseCascaderValue: [],
      caseCascaderOptions: [],
      apiBaseInfoRules: {
        case_name: [
          { required: true, message: '请输入用例名称', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        api_base_id: [
          { required: true, message: '请输入接口ID', trigger: 'blur' }
        ],
        description: [
          { required: false, message: '编写描述信息', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    console.log('获取的父组件caseBaseInfoTag值：', this.caseBaseInfoTag)
    this.apiBaseInfoRuleForm = this.caseBaseInfoTag
    this.loadCaseCascaderData()
  },
  watch: {
    caseBaseInfoTag (newData, oldData) {
      this.apiBaseInfoRuleForm = newData
      console.log('用例基本信息', newData)
    },
    'apiBaseInfoRuleForm.api_base_id' (newId, oldId) {
      // 当 id 属性发生变化时，执行以下代码
      if (!this.apiBaseInfoRuleForm.api_base_id) {
        // this.loadModuleCascaderData()
        this.caseCascaderValue = []
        return
      }
      this.loadCaseCascaderData()
      console.log('新的base——id', newId)
      this.loadGetApiBaseInfoById(newId)
    }
  },
  methods: {
    closeDialogHandle () {
      this.$refs.apiBaseInfoRuleFormRef.resetFields()
    },
    async loadGetApiBaseInfoById (apiBaseId) {
      const queryObj = {
        query_type: 'query_detail',
        api_base_id: apiBaseId
      }
      const { data: res } = await queryApiBaseInfoByID(queryObj)
      if (res.status === 1) {
        this.caseCascaderValue = [res.data.project_id, res.data.module_id, res.data.id]
      }
    },
    caseHandleChange () {
      console.log('勾选的数据', this.caseCascaderValue)
      this.apiBaseInfoRuleForm.api_base_id = this.caseCascaderValue[this.caseCascaderValue.length - 1]
    },
    // 模块级联信息
    async loadCaseCascaderData () {
      const queryObj = { cascader_type: 'api_base' }
      const { data: res } = await queryCascader(queryObj)
      console.log('层级数据', this.caseCascaderOptions)
      if (res.status === 200) {
        this.caseCascaderOptions = res.data
      }
    }
  }
}
</script>

<style>

</style>
