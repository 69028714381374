<template>
  <div class="case">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        用例管理
        <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
          <i class="el-icon-info"></i>
        </el-tooltip>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div slot="header" class="clearfix" style="overflow: hidden;">
        <!-- <span>卡片名称</span> -->
        <div>
          <el-button type="primary" @click="handleAdd">新增用例</el-button>
          <el-button type="primary" @click="handleRunDialog">执行用例</el-button>
        </div>
        <div style="margin-top: 20px;">
          <span style="font-size: 13px;">项目：
            <el-select v-model="projectValue" placeholder="请选择项目" @change="projectValueChange" filterable clearable>
              <el-option
                v-for="item in selectOptionsProject"
                :key="item.id"
                :label="item.project_name"
                :value="item.id">
              </el-option>
            </el-select>
          </span>
          <span style="margin-left: 20px; font-size: 13px;">模块：
            <el-select v-model="moduleValue" placeholder="请选择模块" @change="moduleValueChange" filterable clearable>
              <el-option
                v-for="item in selectOptionsModule"
                :key="item.id"
                :label="item.module_name"
                :value="item.id">
              </el-option>
            </el-select>
          </span>
        </div>
      </div>
      <!-- 当前用例信息展示 -->
      <el-table :data="apiCaseTableData" @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="55px">
        </el-table-column>
        <el-table-column
          prop="id"
          label="用例ID"
          width="80px">
        </el-table-column>
        <el-table-column
          prop="case_name"
          label="用例名称"
          show-overflow-tooltip>
        </el-table-column>
        <!-- <el-table-column
          prop="headers"
          label="headers">
        </el-table-column>
        <el-table-column
          prop="params"
          label="params">
        </el-table-column>
        <el-table-column
          prop="body"
          label="body">
        </el-table-column>
        <el-table-column
          prop="post_operation"
          label="后置操作">
        </el-table-column>
        <el-table-column
          prop="pre_data"
          label="predict">
        </el-table-column> -->
        <el-table-column prop="api_base_id" label="所属接口" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag>{{ apiBaseInfo.find(iterator => iterator.id === scope.row.api_base_id)?.api_path }}</el-tag>
            <!-- {{ scope.row.api_base_id }} -->
          </template>
        </el-table-column>
        <el-table-column prop="status" label="用例状态" width="75px">
          <template slot-scope="scope">
            <el-tag :type="{'0':'danger','1':'success'}[scope.row.status]">
              {{ scope.row.status === '0' ? '停用' :'启用' }}
              <!-- {{ {'0':'待执行','1':'已完成'}[scope.row.cron_status] }} -->
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="description"
          label="用例描述">
        </el-table-column>
        <el-table-column
          prop="created_time"
          label="创建时间">
        </el-table-column>
        <el-table-column
          label="操作"
          width="150">
          <template slot-scope="scope">
            <el-button round type="warning" @click.stop="handleEdit(scope.row)">编辑</el-button>
            <el-button round type="danger" @click.stop="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

    </el-card>

    <!-- 测试用例分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.currentPage"
      :page-sizes="[5, 10, 30, 50]"
      :page-size="pageInfo.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInfo.total_count">
    </el-pagination>

    <el-dialog
      :title=" isAdd ? '新增用例':'编辑用例' "
      :visible.sync="apiCaseDialogVisible"
      width="60%"
      :before-close="dialogCloseHandle"
      @close="closeDialogHandle">
      <el-tabs v-model="activeTagName" @tab-click="tagClickHandle">
        <el-tab-pane label="基本信息" name="caseBaseInfo">
          <api-case-base-info-tag ref="getBaseInfo" :isAdd="isAdd" :caseBaseInfoTag="caseBaseInfoTag"></api-case-base-info-tag>
        </el-tab-pane>
        <el-tab-pane label="Headers" name="caseHeaders">
          <api-case-headers-tag ref="getHeaderInfo" :isAdd="isAdd" :caseHeadersInfoTag="caseHeadersInfoTag"></api-case-headers-tag>
        </el-tab-pane>
        <el-tab-pane label="Params" name="caseParams">
          <api-case-params-tag ref="getParamsInfo" :isAdd="isAdd" :caseParamsInfoTag="caseParamsInfoTag"></api-case-params-tag>
        </el-tab-pane>
        <el-tab-pane label="Body" name="caseBody">
          <api-case-body-tag ref="getBodyInfo" :isAdd="isAdd" :caseBodyInfoTag="caseBodyInfoTag"></api-case-body-tag>
        </el-tab-pane>
        <el-tab-pane label="前置用例" name="third">
          <api-pre-info-tag ref="getpreInfo" :isAdd="isAdd" :casePreInfoTag="casePreInfoTag"></api-pre-info-tag>
        </el-tab-pane>
        <el-tab-pane label="后置操作" name="fourth">
          <api-post-operation-tag ref="getPostOperationInfo" :isAdd="isAdd" :casePostOperationInfoTag="casePostOperationInfoTag"></api-post-operation-tag>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button @click="apiCaseDialogVisible = false">取 消</el-button>
        <el-button v-if="isAdd" type="primary" @click="processAdd">新 增</el-button>
        <el-button v-if="!isAdd" type="primary" @click="processEdit">编 辑</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="运行环境"
      :visible.sync="dialogVisibleRunEnV"
      width="30%">
      <el-select v-model="envValue" placeholder="请选择运行环境">
        <el-option
          v-for="item in envOptions"
          :key="item.id"
          :label="item.env_name"
          :value="item.id">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleRunEnV = false">取 消</el-button>
        <el-button type="primary" @click="handleRun">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { queryApiCaseList, addApiCase, editApiCase, runApiCase, deleteApiCase } from '@/services/apiCase.js'
import { queryApiBaseInfoList } from '@/services/apiBaseInfo.js'
import { queryProjectAll } from '@/services/project.js'
import { queryModuleAll } from '@/services/module.js'
import { queryApiEnvList } from '@/services/apiEnv.js'
import ApiCaseBaseInfoTag from '@/views/apiTest/apiTagInfo/apiCaseBaseInfoTag.vue'
import ApiCaseHeadersTag from '@/views/apiTest/apiTagInfo/apiCaseHeadersTag.vue'
import ApiCaseParamsTag from '@/views/apiTest/apiTagInfo/apiCaseParamsTag.vue'
import ApiCaseBodyTag from '@/views/apiTest/apiTagInfo/apiCaseBodyTag.vue'
import ApiPreInfoTag from '@/views/apiTest/apiTagInfo/apiPreInfoTag.vue'
import ApiPostOperationTag from '@/views/apiTest/apiTagInfo/apiPostOperationTag.vue'

export default {
  name: 'ApiCase',
  components: { ApiCaseBaseInfoTag, ApiCaseHeadersTag, ApiCaseParamsTag, ApiCaseBodyTag, ApiPreInfoTag, ApiPostOperationTag },
  data () {
    return {
      apiCaseTableData: [{
        id: '1',
        case_name: '王小虎',
        description: '上海市普陀区金沙江路 1518 弄'
      }],
      pageInfo: {
        currentPage: 1,
        size: 10,
        total_count: null
      },
      isAdd: true,
      apiCaseDialogVisible: false,
      formLabelWidth: '120px',
      activeTagName: 'caseBaseInfo',
      caseBaseInfoTag: {
        id: '',
        case_name: '',
        api_base_id: '',
        status: '1',
        description: ''
      },
      caseHeadersInfoTag: [
        {
          key: '',
          value: '',
          desc: ''
        }
      ],
      caseParamsInfoTag: [
        {
          key: '',
          value: '',
          desc: ''
        }
      ],
      caseBodyInfoTag: {
        bodyType: 'none',
        bodyContent: ''
      },
      casePreInfoTag: '',
      casePostOperationInfoTag: [],
      apiBaseInfo: [], // 所属接口的信息
      multipleSelection: [],
      multipleSelections: [],
      dialogVisibleRunEnV: false,
      envValue: '',
      envOptions: [],
      projectValue: '',
      selectOptionsProject: [],
      moduleValue: '',
      selectOptionsModule: [],
      apiCaseFormRules: {
        case_name: [
          { required: true, message: '请输入用例名称', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入用例描述', trigger: 'blur' },
          { min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择用例类型', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    this.loadApiCaseList()
    this.loadapiBaseInfoList()
    this.loadGetProjectList()
  },
  methods: {
    async loadApiCaseList () {
      const queryObj = {
        project_id: this.projectValue,
        module_id: this.moduleValue,
        query_type: 'query_list',
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size
      }
      const { data: res } = await queryApiCaseList(queryObj)
      if (res.status === 1) {
        this.apiCaseTableData = res.data
        this.pageInfo.total_count = res.total_count
      }
    },
    async loadapiBaseInfoList () {
      const queryObj = {
        query_type: 'query_list',
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size
      }
      const { data: res } = await queryApiBaseInfoList(queryObj)
      if (res.status === 1) {
        this.apiBaseInfo = res.data
        // for (const iterator of this.apiBaseInfo) {
        //   if (iterator.id === 1) {
        //     return iterator.api_path
        //   }
        // }
        // console.log('接口数据请求成功')
      }
    },
    async loadGetProjectList () {
      const queryObj = {
        query_type: 'query_all'
      }
      const { data: res } = await queryProjectAll(queryObj)
      if (res.status === 1) {
        this.selectOptionsProject = res.data
      }
    },
    async loadGetModuleList () {
      const queryObj = {
        project_id: this.projectValue,
        query_type: 'query_all'
      }
      const { data: res } = await queryModuleAll(queryObj)
      if (res.status === 1) {
        this.selectOptionsModule = res.data
      }
    },
    async projectValueChange () {
      this.moduleValue = ''
      this.selectOptionsModule = []
      this.loadApiCaseList()
      this.loadGetModuleList()
    },
    async moduleValueChange () {
      this.loadApiCaseList()
    },
    apiCaseInfo (operationType) {
      // 获取测试用例所有信息返回 apiCaseInfoObj
      const caseBaseInfoData = this.$refs.getBaseInfo.apiBaseInfoRuleForm
      console.log('获取的用例基本信息：', caseBaseInfoData)
      const caseHeadersData = this.$refs.getHeaderInfo.headersData
      console.log('获取的用例Headers信息：', caseHeadersData)
      const caseParamsData = this.$refs.getParamsInfo.paramsData
      console.log('获取的用例Params信息：', caseParamsData)
      const caseBodyData = this.$refs.getBodyInfo.bodyData
      console.log('获取的用例Body信息：', caseBodyData)
      const casePreData = this.$refs.getpreInfo.preTableData
      console.log('获取的用例PreInfo信息：', casePreData)
      const casePostOperationData = this.$refs.getPostOperationInfo.postOperationData
      console.log('获取的用例PostOperationInfo信息：', casePostOperationData)
      var apiCaseInfoObj = {
        api_operation: operationType,
        caseBaseInfo: caseBaseInfoData,
        caseHeadersInfo: caseHeadersData,
        caseParamsInfo: caseParamsData,
        caseBodyInfo: caseBodyData,
        casePreInfo: casePreData,
        casePostOperationInfo: casePostOperationData
      }
      return apiCaseInfoObj
    },
    handleSizeChange (val) {
      // 用例信息 分页处理(每页)
      this.pageInfo.size = val
      this.loadApiCaseList()
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // 用例信息 分页处理(当前页)
      this.pageInfo.currentPage = val
      this.loadApiCaseList()
      console.log(`当前页: ${val}`)
    },
    handleAdd () {
      // 点击添加按钮
      this.isAdd = true
      this.apiCaseDialogVisible = true
      this.caseBaseInfoTag = {
        id: '',
        case_name: '',
        api_base_id: '',
        status: '1',
        description: ''
      }
      this.caseHeadersInfoTag = [
        {
          key: '',
          value: '',
          desc: ''
        }
      ]
      this.caseParamsInfoTag = [
        {
          key: '',
          value: '',
          desc: ''
        }
      ]
      this.caseBodyInfoTag = {
        bodyType: 'none',
        bodyContent: ''
      }
      this.casePreInfoTag = ''
      this.casePostOperationInfoTag = []
    },
    handleEdit (rowData) {
      // 点击编辑按钮
      this.isAdd = false
      this.apiCaseDialogVisible = true
      console.log('编辑按钮获取的本行内容', rowData)
      this.caseBaseInfoTag = {
        id: rowData.id,
        case_name: rowData.case_name,
        api_base_id: rowData.api_base_id,
        status: rowData.status,
        description: rowData.description
      }
      this.caseHeadersInfoTag = rowData.headers
      this.caseParamsInfoTag = rowData.params
      this.caseBodyInfoTag = rowData.body
      this.casePreInfoTag = rowData.pre_data
      this.casePostOperationInfoTag = rowData.post_operation
    },
    handleDel (rowData) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const deleteObj = { id: rowData.id }
        const { data: res } = await deleteApiCase(deleteObj)
        if (res.status === 1) {
          this.$message.success('删除用例成功')
          this.loadApiCaseList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    tagClickHandle (tab, event) {
      console.log(tab, event)
      console.log('切换tag')
    },
    dialogCloseHandle (done) {
      // doalog关闭提示
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    async processAdd () {
      // 添加用例
      var caseInfoObj = this.apiCaseInfo('add')
      const { data: res } = await addApiCase(caseInfoObj)
      if (res.status === 1) {
        this.apiCaseDialogVisible = false
        this.loadApiCaseList()
        this.$message.success('添加用例成功')
      }
    },
    async processEdit () {
      // 编辑用例
      var caseInfoObj = {
        id: this.$refs.getBaseInfo.apiBaseInfoRuleForm.id,
        case_name: this.$refs.getBaseInfo.apiBaseInfoRuleForm.case_name,
        api_base_id: this.$refs.getBaseInfo.apiBaseInfoRuleForm.api_base_id,
        status: this.$refs.getBaseInfo.apiBaseInfoRuleForm.status,
        description: this.$refs.getBaseInfo.apiBaseInfoRuleForm.description,
        headers: this.$refs.getHeaderInfo.headersData,
        params: this.$refs.getParamsInfo.paramsData,
        body: this.$refs.getBodyInfo.bodyData,
        pre_data: this.$refs.getpreInfo.preTableData,
        post_operation: this.$refs.getPostOperationInfo.postOperationData
      }
      console.log('请求的用例信息', caseInfoObj)
      const { data: res } = await editApiCase(caseInfoObj)
      if (res.status === 1) {
        this.apiCaseDialogVisible = false
        this.loadApiCaseList()
      }
    },
    // 操作当前选中的用例
    handleSelectionChange (val) {
      this.multipleSelection = val
      console.log(this.multipleSelection, '<--选中的数据')
      // 清空之前的 multipleSelections 值，取消选择后重新更新数据
      this.multipleSelections = []
      // 此时 取消/选择 用例的时候，让对应的 ID 信息更新在指定的数据中
      this.multipleSelection.map((item) => {
        // 每次存储数据的时候需要判断当前的 ID 是否已经存在
        if (!this.multipleSelections.includes(item.id)) {
          this.multipleSelections.push(item.id)
        }
      })
      console.log(this.multipleSelections, '<--要传入的测试用例ID列表')
    },
    async handleRunDialog () {
      if (!this.multipleSelections.length > 0) {
        return this.$message.warning('请选择要执行的用例')
      }
      this.dialogVisibleRunEnV = true

      // 获取对应项目下的环境信息
      const queryEnvObj = {
        query_type: 'query_list',
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size
      }
      const { data: resEnv } = await queryApiEnvList(queryEnvObj)
      if (resEnv.status === 1) {
        this.envOptions = resEnv.data
      }
    },
    async handleRun () {
      const deBugObj = {
        env_id: this.envValue,
        caseid_list: this.multipleSelections,
        executionMode: '手动执行用例'
      }
      // 检测是否勾选用例，判定执行类型
      if (this.multipleSelections.length === 1) {
        const { data: res } = await runApiCase(deBugObj)
        console.log(res, '<-- 运行测试用例结果')
        if (res.status === 1) {
          console.log(res, '<-- 用例数为1时')
          this.$store.commit('saveReportInfo', res.data)
          this.$router.push('/apiReport/info')
        } else this.$message.error(res.message)
      } else if (this.multipleSelections.length > 1) {
        const { data: res } = await runApiCase(deBugObj)
        console.log(res, '<-- 运行测试用例结果')
        if (res.status === 1) {
          this.$message.success('运行测试用例成功，请在测试报告中查看运行结果')
          this.loadApiCaseList()
          this.loadapiBaseInfoList()
        } else this.$message.error(res.message)
      }
      this.dialogVisibleRunEnV = false
      this.envValue = ''
    },
    closeDialogHandle () {
      this.$refs.getBaseInfo.closeDialogHandle()
      this.loadApiCaseList()
    }
  }
}
</script>

<style>

</style>
