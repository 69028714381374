<template>
  <div>
    <div slot="header">
      <el-cascader
        v-model="preCaseIdList"
        :options="preOptions"
        :props="{ expandTrigger: 'hover', multiple: true, label: 'name', value: 'id' }"
        collapse-tags
        placeholder="选择增加的用例"
        @change="preHandleChange">
      </el-cascader>
      <el-button type="primary" @click="addCaseHandle" style="margin-left: 50px;" :disabled="preCaseIdList.length>0 ? false:true">新增</el-button>
    </div>
    <el-table
      :data="preTableData"
      style="width: 100%"
      max-height="250"
      size="mini">
      <el-table-column
        width="50px"
        type="index"
        label="序号">
      </el-table-column>
      <el-table-column
        prop="id"
        label="用例ID">
      </el-table-column>
      <el-table-column
        prop="case_name"
        label="用例名称">
      </el-table-column>
      <el-table-column
        prop="api_base_id"
        label="接口地址">
      </el-table-column>
      <el-table-column
        prop="description"
        label="备注信息">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-button @click.native.prevent="deleteRow(scope.$index, preTableData)" type="text" size="small">移除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { queryApiCaseById } from '@/services/apiCase.js'
import { queryCascader } from '@/services/apiCascader.js'
export default {
  name: 'ApiPreInfoTag',
  props: ['isAdd', 'casePreInfoTag'],
  data () {
    return {
      preTableData: [],
      preIdList: [],
      preCaseIdList: [],
      preOptions: []
    }
  },
  created () {
    console.log('获取的父组件casePreInfoTag值：', this.casePreInfoTag)
    this.preInitData()
    this.loadGetPreCascaderData()
  },
  watch: {
    casePreInfoTag (newData, oldData) {
      console.log('获取的心值：', newData)
      console.log('获取的jiu值：', oldData)
      if (newData) {
        this.preTableData = []
        this.preIdList = newData.split(',')
        this.loadGetPreCaseList()
      } else { this.preTableData = [] }
    }
  },
  methods: {
    deleteRow (index, rows) {
      rows.splice(index, 1);
    },
    async loadGetPreCaseList () {
      console.log('获取的前置用例ID列表：', this.preIdList, this.preIdList.length)
      if (this.preIdList.length > 0) {
        for (var i = 0; i < this.preIdList.length; i++) {
          const queryObj = {
            query_type: 'query_detail',
            api_case_id: this.preIdList[i]
          }
          console.log('请求参数：', queryObj)
          const { data: res } = await queryApiCaseById(queryObj)
          console.log('返回接口', res.data)
          if (res.status === 1) {
            this.preTableData.push(res.data)
          }
        }
        this.preIdList = []
      }
    },
    async loadGetPreCascaderData () {
      const queryObj = {
        cascader_type: 'api_case'
      }
      const { data: res } = await queryCascader(queryObj)
      if (res.status === 200) {
        this.preOptions = res.data
      }
    },
    preInitData () {
      // 初始化前置用例
      if (!this.casePreInfoTag) {
        this.preTableData = []
      } else {
        this.preIdList = this.casePreInfoTag.split(',')
        this.loadGetPreCaseList()
      }
    },
    preHandleChange () {},
    addCaseHandle () {
      for (const preCaseId of this.preCaseIdList) {
        this.preIdList.push(preCaseId[3])
      }
      this.loadGetPreCaseList()
      this.preCaseIdList = []
    }
  }
}
</script>

<style lang="scss" scoped>
  /* 使用 ::v-deep 选择器覆盖 Element-UI 样式 */
  ::v-deep .el-cascader__tags {
      flex-wrap: nowrap;
    }

  .el-cascader {
    width: 210px;
  }
  /* 使用 ::v-deep 选择器覆盖 el-input 样式 */
  ::v-deep .el-cascader .el-input {
    /* 在这里添加你想要修改的 el-input 样式 */
    border: 1px solid red;
    width: 250px
  }
</style>
