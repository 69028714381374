<template>
  <div>
    <div class="div-headers">
      <el-row>
        <el-col :span="7">key</el-col>
        <el-col :span="7">value</el-col>
        <el-col :span="7">备注</el-col>
        <el-col :span="3">操作</el-col>
      </el-row>
    </div>
    <div claas="div-headers" v-for="(headersRowData, index) in headersData" :key="index">
      <el-row class="headers-row">
        <el-col :span="7">
          <el-input class="headers-input" v-model="headersRowData.key" :size="inputSize"></el-input>
        </el-col>
        <el-col :span="7">
          <el-input class="headers-input" v-model="headersRowData.value" :size="inputSize"></el-input>
        </el-col>
        <el-col :span="7">
          <el-input class="headers-input" v-model="headersRowData.desc" :size="inputSize"> </el-input>
        </el-col>
        <el-col :span="3">
          <div style="height:32px; display: flex;justify-content: center;align-items: center">
            <div class="icon-btn"><i class="el-icon-close" @click="delHeadersDataHandle(index)"></i></div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div>
      <el-button type="primary" style="width: 100%;margin-top: 5px;" @click="addHeadersDataHandle">新增header</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApiCaseHeadersTag',
  props: ['isAdd', 'caseHeadersInfoTag'],
  data () {
    return {
      inputSize: 'mini',
      headersData: [
        {
          key: '',
          value: '',
          desc: ''
        }
      ]
    }
  },
  created () {
    console.log('获取的父组件caseHeadersInfoTag值：', this.caseHeadersInfoTag)
    this.headerInitData()
  },
  watch: {
    caseHeadersInfoTag (newData, oldData) {
      this.headersData = newData
    }
  },
  methods: {
    addHeadersDataHandle () {
      const headerKey = this.headersData[this.headersData.length - 1].key
      if (headerKey === '' || headerKey === null) {
        this.$message.warning('上一个key值为空')
      } else {
        this.headersData.push(
          {
            key: '',
            value: '',
            desc: ''
          }
        )
      }
    },
    delHeadersDataHandle (index) {
      this.headersData.splice(index, 1)
    },
    headerInitData () {
      // 初始化更新header值
      this.headersData = this.caseHeadersInfoTag
    }
  }
}
</script>

<style lang="scss" scoped>
  .icon-btn {
    height: 12px;
    width: 12px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #f0776c;
    // box-shadow: 0 0 5px #ee3636;
    // left: 18%;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #f0776c;
    background-color: white;
  }
  .div-headers {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    justify-items: stretch;
    text-align: center;
  }
  .headers-row {
    margin-top: 10px;
  }
  .headers-input {
    width: 95%;
  }
</style>
