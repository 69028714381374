<template>
  <div>
    <div>
      <el-collapse v-model="postOperation" accordion>
        <el-collapse-item
        class="collapse-item-header"
        v-for="(postOperationType, index) in postOperationData"
        :key="index"
        :name="index">
          <template slot="title">
            <div>
              <i :class="{'assert_operation': 'el-icon-discover','extract_variables': 'el-icon-grape'}[postOperationType.post_operation_type]"
                style="color: #67C23A;font-size: 16px"
              ></i>
              {{ postOperationTypeDict[postOperationType.post_operation_type] }}
              {{ postOperationType.post_operation_content.post_name }}
              {{ postOperationType.post_operation_content.post_type }}
              ({{ postOperationType.post_operation_content.post_exp }})
              <i class="del-icon-class el-icon-delete" @click.stop="delPostOperationHandle(index)"></i>
            </div>
          </template>
          <div class="collapse-item-body">
            <span>{{ postOperationTypeDict[postOperationType.post_operation_type] === '提取变量' ? '变量' : '断言' }}名称：</span>
            <el-input v-model="postOperationType.post_operation_content.post_name"></el-input>
          </div>
          <div class="collapse-item-body">
            <span>{{ postOperationTypeDict[postOperationType.post_operation_type] }}对象：</span>
            <el-select v-model="postOperationType.post_operation_content.post_type" placeholder="请选择处理对象" style="width: 70%;">
              <el-option
                v-for="item in postSelectoOtions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <!-- <el-input v-model="postOperationType.post_operation_content.post_type" disabled></el-input> -->
          </div>
          <div class="collapse-item-body"  v-if="postOperationType.post_operation_content.post_type !== 'post_time'">
            <span>{{ postOperationTypeDict[postOperationType.post_operation_type] }}表达式：</span>
            <el-select v-model="postOperationType.post_operation_content.post_exp_type" placeholder="请选择类型" style="width: 20%;">
              <el-option label="JsonPath" value="jsonpath"></el-option>
              <el-option label="正则表达式" value="reg"></el-option>
            </el-select>
            <el-input v-model="postOperationType.post_operation_content.post_exp" style="width: 50%;"></el-input>
          </div>
          <div class="collapse-item-body" v-if="postOperationType.post_operation_type==='assert_operation'">
            <span>{{ postOperationTypeDict[postOperationType.post_operation_type] }}：</span>
            <!-- <el-input v-model="postOperationType.post_operation_content.post_logic" style="width: 20%;"></el-input> -->
            <el-select v-model="postOperationType.post_operation_content.post_logic" placeholder="请选择处理对象" style="width: 20%;">
              <el-option
                v-for="item in postLogicSelectoOtions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-input v-model="postOperationType.post_operation_content.post_result" style="width: 50%;"></el-input>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div>
      <el-dropdown trigger="click" style="width: 100%;" @command="dropdownDataHandle">
        <el-button plain style="width: 100%;">
          更多菜单<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown" style="width: 57%;">
          <el-dropdown-item v-for="(operationType, index) in postOperationTypeDict" :key="index" :command="operationType" :icon="operationType === '断言' ? 'el-icon-discover' : 'el-icon-grape'">
            {{ operationType }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApiPostOperationTag',
  props: ['isAdd', 'casePostOperationInfoTag'],
  data () {
    return {
      postOperation: '',
      postOperationData: [],
      postOperationTypeDict: {
        assert_operation: '断言',
        extract_variables: '提取变量'
      },
      postOperationTypeDropdown: '',
      postSelectoOtions: [{
        value: 'post_headers',
        label: 'Response Headers'
      }, {
        value: 'post_body',
        label: 'Response Body'
      }, {
        value: 'post_time',
        label: '耗时'
      }],
      postLogicSelectoOtions: [{
        value: '=',
        label: '='
      }]
    }
  },
  created () {
    console.log('获取的父组件casePostOperationInfoTagg值：', this.casePostOperationInfoTag)
    this.postOperationInitData()
  },
  watch: {
    casePostOperationInfoTag (newData, oldData) {
      this.postOperationData = newData
    }
  },
  methods: {
    dropdownDataHandle (dropDownValue) {
      console.log('下拉值：', dropDownValue)

      // 添加断言数据
      if (dropDownValue === '断言') {
        this.postOperationData.push({
          post_operation_type: 'assert_operation',
          post_operation_content: {
            post_name: '',
            post_type: '',
            post_exp: '',
            post_logic: '',
            post_result: ''
          }
        })
      }

      // 添加提取变量数据
      if (dropDownValue === '提取变量') {
        this.postOperationData.push({
          post_operation_type: 'extract_variables',
          post_operation_content: {
            post_name: '',
            post_type: '',
            post_exp: ''
          }
        })
      }
    },
    delPostOperationHandle (index) {
      console.log('删除后置操作，删除位置', index)
      this.postOperationData.splice(index, 1)
      console.log(this.postOperationData)
    },
    postOperationInitData () {
      this.postOperationData = this.casePostOperationInfoTag
    }
  }
}
</script>

<style lang="scss" scoped>
  .collapse-item-header {
    background-color: #f8f8f8!important; /* 设置自定义的背景色 */
    margin-bottom: 5px;
    position: relative;
    vertical-align: middle;
  }
  .del-icon-class {
    position: absolute;
    // inset-block-start: 20px;
    inset-inline-end: 20px;
    margin-top: 17px;
    color: #F56C6C;
  }
  .collapse-item-body {
    display: flex;
    justify-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  span {
    width: 120px;
    display: flex;
    justify-content: flex-end
  }
  .el-input {
    width: 70%;
  }
</style>
