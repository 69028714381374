<template>
  <div>
    <div class="div-params">
      <el-row>
        <el-col :span="7">key</el-col>
        <el-col :span="7">value</el-col>
        <el-col :span="7">备注</el-col>
        <el-col :span="3">操作</el-col>
      </el-row>
    </div>
    <div claas="div-params" v-for="(paramsData, index) in paramsData" :key="index">
      <el-row class="params-row">
        <el-col :span="7">
          <el-input class="params-input" v-model="paramsData.key" :size="inputSize"></el-input>
        </el-col>
        <el-col :span="7">
          <el-input class="params-input" v-model="paramsData.value" :size="inputSize"></el-input>
        </el-col>
        <el-col :span="7">
          <el-input class="params-input" v-model="paramsData.desc" :size="inputSize"> </el-input>
        </el-col>
        <el-col :span="3">
          <div style="height:32px; display: flex;justify-content: center;align-items: center">
            <div class="icon-btn"><i class="el-icon-close" @click="delParamsDataHandle(index)"></i></div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div>
      <el-button type="primary" style="width: 100%;margin-top: 5px;" @click="addParamsDataHandle">新增 params</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApiCaseParamsTag',
  props: ['isAdd', 'caseParamsInfoTag'],
  data () {
    return {
      inputSize: 'mini',
      paramsData: [
        {
          key: '',
          value: '',
          desc: ''
        }
      ]
    }
  },
  watch: {
    caseParamsInfoTag (newData, oldData) {
      this.paramsData = newData
    }
  },
  created () {
    console.log('获取的父组件caseParamsInfoTag值：', this.caseParamsInfoTag)
    this.paramsInitData()
  },
  methods: {
    addParamsDataHandle () {
      const headerKey = this.paramsData[this.paramsData.length - 1].key
      if (headerKey === '' || headerKey === null) {
        this.$message.warning('上一个key值为空')
      } else {
        this.paramsData.push(
          {
            key: '',
            value: '',
            desc: ''
          }
        )
      }
    },
    delParamsDataHandle (index) {
      this.paramsData.splice(index, 1)
    },
    paramsInitData () {
      // 初始化params
      this.paramsData = this.caseParamsInfoTag
    }
  }
}
</script>

<style lang="scss" scoped>
  .icon-btn {
    height: 12px;
    width: 12px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #f0776c;
    // box-shadow: 0 0 5px #ee3636;
    // left: 18%;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #f0776c;
    background-color: white;
  }
  .div-params {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    justify-items: stretch;
    text-align: center;
  }
  .params-row {
    margin-top: 10px;
  }
  .params-input {
    width: 95%;
  }
</style>
