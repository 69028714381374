import request from '@/utils/request.js'

import { urlPath } from './apiBaseInfo'

// 通过 api_case_id 查询接口用例信息
export const queryApiCaseById = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'apiCase',
    params: {
      ...data
    }
  })
}

// 查询接口用例列表
export const queryApiCaseList = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'apiCase',
    params: {
      ...data
    }
  })
}

// 查询接口用例级联信息
export const queryApiCaseCascader = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'apiCase',
    params: {
      ...data
    }
  })
}

// 新增api用例
export const addApiCase = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'apiCase',
    data
  })
}

// 编辑api用例
export const editApiCase = (data) => {
  return request({
    method: 'PUT',
    url: urlPath + 'apiCase',
    data
  })
}

// 运行用例
export const runApiCase = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'runCaseApi',
    data
  })
}

// 删除用例
export const deleteApiCase = (data) => {
  return request({
    method: 'DELETE',
    url: urlPath + 'apiCase',
    data
  })
}

// debug
export const debugApi = (data) => {
  return request({
    method: 'GET',
    url: 'hello',
    params: {
      ...data
    }
  })
}
